import { createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import { ElMessage } from 'element-plus';
import 'element-plus/es/components/message/style/css'

import { mixins } from "@/plugins/mixins";
export default {
  name: "depositRule",
  mixins: [mixins],
  components: {},
  data() {
    return {
      showDialog: false,
      switchLoading: false,
      currentId: null,
      detailDrawer: false,
      formModel: {
        newPwd: '',
        userId: ''
      },
      formRules: {
        newPwd: [{
          required: true,
          message: '请输入新密码',
          trigger: 'blur'
        }]
      },
      columns: [{
        label: '姓名',
        prop: 'userName'
      }, {
        label: '联系电话',
        prop: 'userPhone'
      }, {
        label: '身份证号码',
        prop: 'userID'
      }, {
        label: '角色',
        prop: 'userRole'
      }, {
        label: '状态',
        customRender: props => {
          if (this.$hasPermission('maintenanceUser_status')) {
            return _createVNode(_resolveComponent("el-switch"), {
              "modelValue": props.row.userStatus,
              "onUpdate:modelValue": $event => props.row.userStatus = $event,
              "loading": this.switchLoading,
              "before-change": () => {
                return new Promise((resolve, reject) => {
                  this.changeEnabled(props.row, resolve, reject);
                });
              }
            }, null);
          } else {
            return props.row.userStatus ? '正常' : '锁定';
          }
        }
      }],
      topButtons: [{
        name: '新增',
        code: 'maintenanceUser_add',
        type: 'primary',
        click: this.add,
        permissions: ['maintenanceUser_add']
      }],
      linkButtons: [{
        name: '编辑',
        code: 'maintenanceUser_edit',
        click: this.edit,
        permissions: ['dictionary_edit']
      }, {
        name: '重置密码',
        code: 'maintenanceUser_pwd',
        click: this.czPwd,
        permissions: ['maintenanceUser_pwd']
      }],
      searchItems: [{
        label: '姓名',
        prop: 'userName'
      }, {
        label: '联系电话',
        prop: 'userPhone'
      }, {
        label: '身份证号码',
        prop: 'userID'
      }]
    };
  },
  methods: {
    changeEnabled(info, resolve, reject) {
      this.switchLoading = true;
      const callBack = res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          return resolve(true);
        } else {
          ElMessage.error(res.message);
          return reject(new Error('Error'));
        }
      };
      if (info.userStatus) {
        this.$api.business.maintenanceUser.disable({
          id: info.id
        }).then(callBack).finally(() => this.switchLoading = false);
      } else {
        this.$api.business.maintenanceUser.enable({
          id: info.id
        }).then(callBack).finally(() => this.switchLoading = false);
      }
    },
    add() {
      this.$router.push('/maintenanceUserAdd');
    },
    edit(row) {
      this.$router.push({
        path: '/maintenanceUserAdd',
        query: {
          id: row.id
        }
      });
    },
    czPwd(row) {
      this.formModel.newPwd = '';
      this.formModel.userId = row.id;
      this.showDialog = true;
    },
    submitForm() {
      1;
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.showDialog = false;
            } else {
              ElMessage.error(res.message);
            }
          };
          this.$api.business.maintenanceUser.forceChangePwd(this.formModel).then(callBack);
        }
      });
    }
  }
};